const clientId = 'xjtqvrx0j95t0jjma4oa7yd2m7gujy';
const clientPassword = '8nbq7442xcdz5mus5d6d1mj733r2vm';

// const brodcasterId = 51229960;
const brodcasterId = 36070875;


function getTwitchAuthorization() {
    let url = `https://id.twitch.tv/oauth2/token?client_id=${clientId}&client_secret=${clientPassword}&grant_type=client_credentials`;

    return fetch(url, {
    method: "POST",
    })
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
}

async function getStreams() {
    const endpoint = `https://api.twitch.tv/helix/streams?user_login=arteezy`;
    // const endpoint = `https://api.twitch.tv/helix/users?login=gorc`;

    let authorizationObject = await getTwitchAuthorization();
    let { access_token, expires_in, token_type } = authorizationObject;

    //token_type first letter must be uppercase    
    token_type =
    token_type.substring(0, 1).toUpperCase() +
    token_type.substring(1, token_type.length);

    let authorization = `${token_type} ${access_token}`;

    let headers = {
    authorization,
    "Client-Id": clientId,
    };

    fetch(endpoint, {
    headers,
    })
    .then((res) => res.json())
    .then((data) => renderStreams(data));
}

function renderStreams(data) {
    // console.log(data);
}

getStreams();